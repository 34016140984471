import React from 'react'
import Container from '../Container'
import './Product.scss'

class Product extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      output: '',
    }
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if(window.innerWidth > 992) {
      this.inputRef.current.focus()
    }
  }

  handleInputChange = event => {
    const value = event.target.value

    try {
      const decodedValue = decodeURIComponent(value)
      this.setState({
        output: decodedValue,
        invalid: false,
      })
    } catch (err) {
      this.setState({
        output: '',
        invalid: true,
      })
    }
  }

  copyToClipboard = event => {
    if (!event.target.value) {
      return
    }

    this.selectArea(event)

    if (document.queryCommandSupported('copy')) {
      document.execCommand('copy')
    }
  }

  selectArea = event => {
    if (event.target.value) {
      event.target.select()
    }
  }

  render () {
    return (
      <div className="product">
        <Container>
          <div className="product-form">
            <h1 className="product-heading">URL Decode online</h1>
            <form id="url-decoder-form">
              <div
                className={'form-item ' + (this.state.invalid ? 'invalid' : '')}
              >
                <textarea
                  className="form-control input-area "
                  placeholder="Type or paste your URL encoded input here..."
                  ref={this.inputRef}
                  onChange={this.handleInputChange}
                />
                {this.state.invalid ? (
                  <div className="error">
                    That's not a valid URL encoded string
                  </div>
                ) : null}
              </div>
              <div className="form-item">
                <textarea
                  className="form-control output-area"
                  placeholder="URL decoded output goes here..."
                  readOnly
                  onFocus={this.selectArea}
                  onClick={this.copyToClipboard}
                  value={this.state.output}
                />
              </div>
              <div className="info">
                <span>The tool uses <code>UTF-8</code> encoding scheme.</span>
                <span className="bug">
                  Found a bug? <a href="mailto:rajeevhub@gmail.com?subject=Bug in URLDecoder">report!</a>
                </span>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

export default Product
