import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../layouts'
import SEO from '../components/SEO'
import Product from '../components/Product'
import Container from '../components/Container'
import PostListHorizontal from '../components/PostListHorizontal'
import PostCard from '../components/PostCard'
import Adsense from '../components/Adsense'
import Disqus from '../components/Disqus'
import Share from '../components/Share'

import './index.scss'

class Index extends React.Component {
  render () {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO article={false} />
        <Product siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-ad">
          <Adsense type="article-top-responsive" />
        </div>
        <div className="home-blog-section">
          <Container>
            <h2 className="blog-latest-title">Latest From The Blog</h2>
            <div className="blog-featured">
              <PostCard
                post={posts[0].node}
                type="featured"
                siteMeta={this.props.data.site.siteMetadata}
              />
            </div>
            <div className="blog-latest">
              <PostListHorizontal
                posts={posts.slice(1, 5)}
                siteMeta={this.props.data.site.siteMetadata}
              />
            </div>
            <div className="home-ad">
              <Adsense type="article-bottom-responsive" />
            </div>
            <div className="blog-about">
              <h2 className="blog-about-title">
                About {this.props.data.site.siteMetadata.name}
              </h2>
              <div className="info-group">
                <div className="one-half">
                  <p>
                    URL Decoder is the #1 online tool for decoding URLs. Get
                    started by typing or pasting a URL encoded string in the
                    input text area, the tool will automatically decode your
                    input string in real time.
                  </p>
                  <p>
                    If the input is not a valid URL encoded string, then the
                    input text area will turn red and the output textarea will
                    be cleared.
                  </p>
                  <p>
                    Once your input string is decoded, you can click in the
                    output text area to copy the decoded URL.
                  </p>
                  <p>
                    Note that, our tool assumes that the input is encoded using{' '}
                    <code>UTF-8</code> encoding scheme. The world wide web
                    consortium recommends using <code>UTF-8</code> encoding
                    scheme when working with URLs.
                  </p>
                  <p>
                    Our website also contains various articles about how to
                    decode URLs in different programming languages. You should
                    definitely check them out in the <a href="/blog/">blog</a>{' '}
                    section.
                  </p>
                  <p>
                    We also have a tool for encoding URLs. It is hosted at{' '}
                    <a href="https://www.urlencoder.io">
                      https://www.urlencoder.io
                    </a>
                    . Do check that out if you want to encode URL components.
                  </p>
                  <h3>What is URL Decoding and why is it required?</h3>
                  <p>
                    URL decoding is the inverse process of URL encoding. It is
                    used to parse query strings or path parameters passed in
                    URLs. It is also used to decode HTML form parameters that
                    are submitted with{' '}
                    <code>application/x-www-form-urlencoded</code> MIME format
                  </p>
                  <p>
                    URLs, as you might know, can only contain a limited set of
                    characters from the US-ASCII character set. These characters
                    include Alphabets (<code>A-Z a-z</code>), Digits (
                    <code>0-9</code>), hyphen (<code>-</code>), underscore (
                    <code>_</code>), tilde (<code>~</code>), and dot (
                    <code>.</code>). Any character outside this allowed set is
                    encoded using URL encoding or Percent encoding.
                  </p>
                  <p>
                    This is why, it becomes necessary to decode query strings or
                    path parameters passed in URLs to get the actual values.
                  </p>
                </div>
                <div className="one-half">
                  <h3>How does URL Decoding work?</h3>
                  <p>
                    Following rules are applied for decoding query strings, path
                    parameters, or HTML form parameters:
                  </p>
                  <ul>
                    <li>
                      The alphanumeric characters <code>a - z</code>,{' '}
                      <code>A - Z</code>, and <code>0 - 9</code> remain the
                      same.
                    </li>
                    <li>
                      Safe characters like <code>-</code>, <code>_</code>,{' '}
                      <code>~</code>, and <code>~</code> remain the same.
                    </li>
                    <li>
                      Any sequence of the form <code>%xy</code> is treated as a
                      byte where <code>xy</code> is the two-digit hexadecimal
                      representation of the 8 bits. Then, all substrings that
                      contain one or more of these byte sequences consecutively
                      is replaced by the character(s) whose encoding would
                      result in those consecutive bytes.
                    </li>
                  </ul>
                  <h3>Common URL decoding examples</h3>
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Encoded String</th>
                          <th>Decoded Character</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>%20</td>
                          <td>space</td>
                        </tr>
                        <tr>
                          <td>%25</td>
                          <td>%</td>
                        </tr>
                        <tr>
                          <td>%26</td>
                          <td>&amp;</td>
                        </tr>
                        <tr>
                          <td>%2B</td>
                          <td>+</td>
                        </tr>
                        <tr>
                          <td>%2F</td>
                          <td>/</td>
                        </tr>
                        <tr>
                          <td>%3D</td>
                          <td>=</td>
                        </tr>
                        <tr>
                          <td>%3F</td>
                          <td>?</td>
                        </tr>
                        <tr>
                          <td>%40</td>
                          <td>@</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p>
                    All right, So what are you waiting for? Start using our
                    online tool from now on to decode URLs in a safe, secure,
                    and easy way.
                  </p>
                  <p>
                    You can find out more information on the{' '}
                    <a href="/blog/">blog</a>.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="home-ad">
          <Adsense type="article-bottom-responsive" />
        </div>
        <Container>
          <div className="page-share">
            <Share title={this.props.data.site.siteMetadata.title} pathname={this.props.location.pathname} />
          </div>
        </Container>
        <Container>
          <div className="page-comments">
            <Disqus location={this.props.location} 
                    disqusShortname={this.props.data.site.siteMetadata.disqusShortname} />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
